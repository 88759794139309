@import url(https://fonts.googleapis.com/css?family=Archivo+Black);
body {
	font-family: Varela;
	font-size: 70px;
	background-color: #010000;
	font-weight: 600px;
	-webkit-transition: .4s ease-in-out;
	transition: .4s ease-in-out;
	font-style: normal;
}
a {
	text-decoration: none;
	-webkit-transition: .4s ease-in-out;
	transition: .4s ease-in-out;
	cursor: pointer;
}
a:hover {
	color: #8b0000;
	text-decoration: none;
	-webkit-transition: .4s ease-in-out;
	transition: .4s ease-in-out;
}
.container-fluid {
	line-height: 24px;
}
@font-face {
	font-family: hacked;
	src: url(/HACKED.ttf);
}
@font-face {
	font-family: archivo;
	src: url(https://fonts.googleapis.com/css?family=Archivo+Black) format("truetype");
}
header {
	position: absolute;
	display: inline-block;
	width: 100%;
	background: rgba(0, 0, 0, .7);
	color: #fff;
	height: 100%;
}
header .name {
	top: 44%;
	justify-content: center;
	font-family: hacked;
	font-size: 6vw;
	letter-spacing: .05em;
	-webkit-transition: .4s ease-in-out;
	transition: .4s ease-in-out;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	position: absolute;
	color: #fff;
}
@-webkit-keyframes noise-anim {
	0% {
		clip: rect(45px, 9999px, 43px, 0);
	}
	5% {
		clip: rect(17px, 9999px, 3px, 0);
	}
	10% {
		clip: rect(97px, 9999px, 71px, 0);
	}
	15% {
		clip: rect(64px, 9999px, 73px, 0);
	}
	20% {
		clip: rect(47px, 9999px, 88px, 0);
	}
	25% {
		clip: rect(17px, 9999px, 89px, 0);
	}
	30% {
		clip: rect(8px, 9999px, 11px, 0);
	}
	35% {
		clip: rect(34px, 9999px, 34px, 0);
	}
	40% {
		clip: rect(69px, 9999px, 25px, 0);
	}
	45% {
		clip: rect(20px, 9999px, 92px, 0);
	}
	50% {
		clip: rect(3px, 9999px, 69px, 0);
	}
	55% {
		clip: rect(24px, 9999px, 72px, 0);
	}
	60% {
		clip: rect(48px, 9999px, 53px, 0);
	}
	65% {
		clip: rect(44px, 9999px, 71px, 0);
	}
	70% {
		clip: rect(45px, 9999px, 17px, 0);
	}
	75% {
		clip: rect(5px, 9999px, 88px, 0);
	}
	80% {
		clip: rect(97px, 9999px, 10px, 0);
	}
	85% {
		clip: rect(27px, 9999px, 27px, 0);
	}
	90% {
		clip: rect(49px, 9999px, 31px, 0);
	}
	95% {
		clip: rect(1px, 9999px, 76px, 0);
	}
	100% {
		clip: rect(28px, 9999px, 34px, 0);
	}
}
@keyframes noise-anim {
	0% {
		clip: rect(45px, 9999px, 43px, 0);
	}
	5% {
		clip: rect(17px, 9999px, 3px, 0);
	}
	10% {
		clip: rect(97px, 9999px, 71px, 0);
	}
	15% {
		clip: rect(64px, 9999px, 73px, 0);
	}
	20% {
		clip: rect(47px, 9999px, 88px, 0);
	}
	25% {
		clip: rect(17px, 9999px, 89px, 0);
	}
	30% {
		clip: rect(8px, 9999px, 11px, 0);
	}
	35% {
		clip: rect(34px, 9999px, 34px, 0);
	}
	40% {
		clip: rect(69px, 9999px, 25px, 0);
	}
	45% {
		clip: rect(20px, 9999px, 92px, 0);
	}
	50% {
		clip: rect(3px, 9999px, 69px, 0);
	}
	55% {
		clip: rect(24px, 9999px, 72px, 0);
	}
	60% {
		clip: rect(48px, 9999px, 53px, 0);
	}
	65% {
		clip: rect(44px, 9999px, 71px, 0);
	}
	70% {
		clip: rect(45px, 9999px, 17px, 0);
	}
	75% {
		clip: rect(5px, 9999px, 88px, 0);
	}
	80% {
		clip: rect(97px, 9999px, 10px, 0);
	}
	85% {
		clip: rect(27px, 9999px, 27px, 0);
	}
	90% {
		clip: rect(49px, 9999px, 31px, 0);
	}
	95% {
		clip: rect(1px, 9999px, 76px, 0);
	}
	100% {
		clip: rect(28px, 9999px, 34px, 0);
	}
}
header .name:after {
	content: attr(data-text);
	position: absolute;
	left: 2px;
	text-shadow: -1px 0 red;
	top: 0;
	color: #fff;
	background: #000;
	overflow: hidden;
	clip: rect(0, 900px, 0, 0);
	animation: noise-anim 2s infinite linear alternate-reverse;
}
@-webkit-keyframes noise-anim-2 {
	0% {
		clip: rect(71px, 9999px, 46px, 0);
	}
	5% {
		clip: rect(94px, 9999px, 53px, 0);
	}
	10% {
		clip: rect(83px, 9999px, 3px, 0);
	}
	15% {
		clip: rect(92px, 9999px, 81px, 0);
	}
	20% {
		clip: rect(63px, 9999px, 78px, 0);
	}
	25% {
		clip: rect(9px, 9999px, 34px, 0);
	}
	30% {
		clip: rect(43px, 9999px, 96px, 0);
	}
	35% {
		clip: rect(1px, 9999px, 18px, 0);
	}
	40% {
		clip: rect(89px, 9999px, 44px, 0);
	}
	45% {
		clip: rect(24px, 9999px, 44px, 0);
	}
	50% {
		clip: rect(97px, 9999px, 24px, 0);
	}
	55% {
		clip: rect(28px, 9999px, 8px, 0);
	}
	60% {
		clip: rect(74px, 9999px, 37px, 0);
	}
	65% {
		clip: rect(63px, 9999px, 64px, 0);
	}
	70% {
		clip: rect(62px, 9999px, 88px, 0);
	}
	75% {
		clip: rect(58px, 9999px, 79px, 0);
	}
	80% {
		clip: rect(81px, 9999px, 30px, 0);
	}
	85% {
		clip: rect(71px, 9999px, 73px, 0);
	}
	90% {
		clip: rect(80px, 9999px, 90px, 0);
	}
	95% {
		clip: rect(56px, 9999px, 29px, 0);
	}
	100% {
		clip: rect(25px, 9999px, 22px, 0);
	}
}
@keyframes noise-anim-2 {
	0% {
		clip: rect(71px, 9999px, 46px, 0);
	}
	5% {
		clip: rect(94px, 9999px, 53px, 0);
	}
	10% {
		clip: rect(83px, 9999px, 3px, 0);
	}
	15% {
		clip: rect(92px, 9999px, 81px, 0);
	}
	20% {
		clip: rect(63px, 9999px, 78px, 0);
	}
	25% {
		clip: rect(9px, 9999px, 34px, 0);
	}
	30% {
		clip: rect(43px, 9999px, 96px, 0);
	}
	35% {
		clip: rect(1px, 9999px, 18px, 0);
	}
	40% {
		clip: rect(89px, 9999px, 44px, 0);
	}
	45% {
		clip: rect(24px, 9999px, 44px, 0);
	}
	50% {
		clip: rect(97px, 9999px, 24px, 0);
	}
	55% {
		clip: rect(28px, 9999px, 8px, 0);
	}
	60% {
		clip: rect(74px, 9999px, 37px, 0);
	}
	65% {
		clip: rect(63px, 9999px, 64px, 0);
	}
	70% {
		clip: rect(62px, 9999px, 88px, 0);
	}
	75% {
		clip: rect(58px, 9999px, 79px, 0);
	}
	80% {
		clip: rect(81px, 9999px, 30px, 0);
	}
	85% {
		clip: rect(71px, 9999px, 73px, 0);
	}
	90% {
		clip: rect(80px, 9999px, 90px, 0);
	}
	95% {
		clip: rect(56px, 9999px, 29px, 0);
	}
	100% {
		clip: rect(25px, 9999px, 22px, 0);
	}
}
header .name:before {
	content: attr(data-text);
	position: absolute;
	left: -2px;
	text-shadow: 1px 0 #00f;
	top: 0;
	color: red;
	background: #000;
	overflow: hidden;
	clip: rect(0, 900px, 0, 0);
	animation: noise-anim-2 3s infinite linear alternate-reverse;
}
header .certification {
	position: absolute;
	top: 49.5%;
	left: 71%;
	font-size: 1.1vw;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}
header .nav-img {
	width: 21px;
	height: 21px;
	margin-right: 3px;
	margin-bottom: 4px;
}
header nav,
header nav ul,
header nav ul li {
	margin: 0;
	padding: 0;
	line-height: .9;
}
header nav ul li.second,
header nav ul.second,
header nav.second {
	display: flex;
	justify-content: center;
	padding: 0;
}
header nav {
	position: absolute;
	top: 56.5%;
	font-size: 40px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
header nav ul li {
	list-style-type: none;
	display: inline-block;
	margin-right: 30px;
}
header nav ul li.last {
	margin-right: 0;
}
header nav ul li a {
	font-size: 1.4vw;
	line-height: normal;
	color: #fff;
}
img.bg {
	width: 50%;
	height: 100%;
	position: absolute;
	display: inline-block;
	vertical-align: middle;
	top: 0;
}
.but {
	display: inline-block;
	color: #8b0000;
	vertical-align: bottom;
	font-family: archivo, sans-serif;
	font-style: normal;
	bottom: 0;
	font-size: 1.2vw;
	position: fixed;
}
@media screen and (max-width: 600px) {
	img.bg {
		min-height: 10%;
		min-width: 50%;
		height: 50%;
		width: auto;
		position: absolute;
		top: 25%;
		margin-right: auto;
		margin-left: auto;
	}
	header .name {
		font-size: 10vw;
		white-space: nowrap;
	}
	header .certification {
		font-size: 4.2vw;
		top: 47.5%;
		left: 71%;
		white-space: nowrap;
	}
	header nav {
		top: 53%;
		list-style-type: none;
		white-space: nowrap;
		font-size: 70%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	header nav ul li {
		display: inline-block;
		list-style-type: none;
		margin-right: 5%;
		line-height: 60%;
	}
	header nav ul li.last {
		margin-right: 0;
	}
	header nav ul li a {
		font-size: 4.1vw;
		line-height: normal;
		color: #fff;
	}
	.but {
		display: inline-block;
		color: #8b0000;
		vertical-align: bottom;
		font-family: archivo, sans-serif;
		font-style: normal;
		bottom: 0;
		font-size: 4vw;
		position: fixed;
	}
}

